<!--<template>
    <v-app>
        <div class="login-content color-change">
            <div class="login-container">
                <div class="login-logo animated fadeInDown slow">
                    <img src="img/logo-store-3.png" width="100">
                </div>		
                <div class="login-title animated fadeInDown slow">
                    <span class="login-title-text">Inicia sesión</span>
                </div>
                <div class="login-subtitle animated fadeInDown slow">			
                    <span>Inicia sesión y comienza a administrar tu tienda!</span>
                </div>		
                <div class="login-form">
                    <input v-model="username" class="login-textfield animated fadeInDown slow" type="text" name="usuario" placeholder="Usuario">
                    <input v-model="password" 
                        class="login-textfield animated fadeInDown slow" 
                        type="password" 
                        name="pssword" 
                        placeholder="Contraseña"
                        @keydown="handleInputSearchByEnter"
                    >
                    <button @click="handleLogin" class="login-button animated fadeInUp slower">Ingresar</button>
                </div>
            </div>
        </div>

        <v-snackbar
            v-model="show_message"            
            color="error"
            :timeout="3000"
            :top="'top'"
        >
            Credenciales incorrectas
            <v-btn
            dark
            text
            @click="snackbar = false"
            >
                Cerrar
            </v-btn>
        </v-snackbar>
        <notification-success></notification-success>
    </v-app>
</template>-->

<template>
    <v-app>
      <div class="contenedor-superior">
        <div class="forma01"></div>
        <div class="forma02"></div>
        <div class="card01">
          <div
            style="
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              flex-direction: column;
              align-items: center;
            "
          >
            <div class="contenedor-logo">
              <img
                src="img/logo_pausa.png"
                style="width: auto; height: 101px;"
              />
            </div>
            <label for="" class="login-title-text">Iniciar Sesion</label>
            <div style="position: relative; width: 60%">
              <input
                v-model="username"
                class="login-textfield-custom"
                type="text"
                name="usuario"
                placeholder="Usuario"
              />
              <span style="position: absolute; left: 15px; top: 34px">
                <i class="fa fa-user fa-lg" style="color: #d3d3d3"></i>
              </span>
            </div>
            <div style="position: relative; width: 60%">
              <input
                v-model="password"
                class="login-textfield-custom"
                type="password"
                name="pssword"
                placeholder="Contraseña"
                @keydown="handleInputSearchByEnter"
              />
              <span style="position: absolute; left: 15px; top: 34px">
                <i class="fas fa-key fa-lg" style="color: #d3d3d3"></i>
              </span>
            </div>
            <button
              @click="handleLogin"
              class="login-button-custom animated fadeInUp slower"
            >
              ACCEDER
            </button>
          </div>
        </div>
        <div class="card02">
          <div style="position: relative; width: 100%; height: 100%;">
            <img src="/img/cafe.webp" alt="logo" class="background-logo" />
            <div
              style="
                display: flex;
                width: 100%;
                height: 100%;
                justify-content: center;
                align-items: center;
                
              "
            >
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  padding: 0 85px 0 75px;
                  z-index: 10;
                "
              >
                <label
                  for=""
                  class="login-title-text"
                  style="color: white; font-size: 3rem !important"
                  >Bienvenido</label
                >
                <label
                  for=""
                  class="login-title-text"
                  style="
                    font-size: 1.1rem !important;
                    color: white;
                    font-weight: 500 !important;
    
                    text-align: center;
                  "
                  >Ingrese sus credenciales para acceder al sistema, en caso de no
                  recordar comuníquese con el Administrador</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <v-snackbar
        v-model="show_message"
        color="error"
        :timeout="3000"
        :top="'top'"
      >
        Credenciales incorrectas
        <v-btn dark text @click="snackbar = false"> Cerrar </v-btn>
      </v-snackbar>
      <notification-success></notification-success>
    </v-app>
  
    <!-- <v-app>
          <div class="login-content color-change">
              <div class="login-container">
                  <div class="login-logo animated fadeInDown slow">
                      <img src="img/logo-store-3.png" width="100">
                  </div>		
                  <div class="login-title animated fadeInDown slow">
                      <span class="login-title-text">Inicia sesión</span>
                  </div>
                  <div class="login-subtitle animated fadeInDown slow">			
                      <span>Inicia sesión y comienza a administrar tu tienda!</span>
                  </div>		
                  <div class="login-form">
                      <input v-model="username" class="login-textfield animated fadeInDown slow" type="text" name="usuario" placeholder="Usuario">
                      <input v-model="password" 
                          class="login-textfield animated fadeInDown slow" 
                          type="password" 
                          name="pssword" 
                          placeholder="Contraseña"
                          @keydown="handleInputSearchByEnter"
                      >
                      <button @click="handleLogin" class="login-button animated fadeInUp slower">Ingresar</button>
                  </div>
              </div>
          </div>
  
          <v-snackbar
              v-model="show_message"            
              color="error"
              :timeout="3000"
              :top="'top'"
          >
              Credenciales incorrectas
              <v-btn
              dark
              text
              @click="snackbar = false"
              >
                  Cerrar
              </v-btn>
          </v-snackbar>
          <notification-success></notification-success>
      </v-app> -->
  </template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex'

import router from '@/router/index';
import NotificationSuccess from '../../components/notifications/NotificationSuccess';
//import ArrayTools from '../../helpers/ArrayTools'

export default {
    name: 'SignIn',
    components: {
        NotificationSuccess
    },
    data() {
        return {
            show_message: false,
            showLogin: true,
            showCompanies: false,
            username: null,
            password: null,
            companies: [],
            value_company: null,
            disabled_company: false,
            sedes: [],
            value_sede: null,
            disabled_sede: false,
            offices: [],
            value_office: null
        }
    },
    computed: {
        ...mapState('authentication', [
            'token',
            'select_office',
            'user_companies',
            'user_sedes',
            'user_offices'
        ])
    },
    methods: {
        ...mapActions('authentication', [
            'login',
            'set_office_login'
        ]),
        ...mapGetters('authentication', [
            'isLoggedIn'
        ]),
        async handleLogin() {
            if (this.username ==null || this.password == null) {
                this.show_message = true;
            } else {
                this.show_message = false;
                await this.login({username: this.username, password: this.password});                
            }                        
        },
        handleInputSearchByEnter(KeyboardEvent){
            if (KeyboardEvent.code == 'Enter') {
                this.handleLogin();
            }
        },
    },
    created() {        
        if (this.isLoggedIn()) {
            router.push('/app/dashboard');
        }
    }
}
</script>

<style scoped>
html {
  height: 100%;
  background-color: #f4f6f8 !important;
}

body {
  margin: 0px !important;
  height: 100%;
  color: #ffffff;
  font-size: 16px !important;
}

.forma01 {
  width: 13%;
  height: 25%;
  border-radius: 50%;
  position: absolute;
  left: -5%;
  top: -9%;
  /* background-image: radial-gradient(
    circle at 20.12% -14.09%,
    #de56eb 0,
    #c34cee 16.67%,
    #a343f0 33.33%,
    #783cf2 50%,
    #3039f4 66.67%,
    #0038f5 83.33%,
    #003af6 100%
  ); */
  /* background-image: radial-gradient(
    circle at -14.09% 20.12%,
    #98cd00 0,
    #7bcc00 16.67%,
    #57c900 33.33%,
    #13c600 50%,
    #00c21d 66.67%,
    #00be31 83.33%,
    #00bb42 100%
  ); */
  /*background-image: linear-gradient(
    335deg,
    #c6196b 0,
    #b0106a 16.67%,
    #970765 33.33%,
    #7c005d 50%,
    #610053 66.67%,
    #4a004a 83.33%,
    #350042 100%
  );*/
  background-image: radial-gradient(
    circle at 93.87% 80.72%, 
    #565a5e 0, 
    #545556 8.33%, 
    #52504f 16.67%, 
    #504946 25%, 
    #4e413e 33.33%, 
    #4c3936 41.67%, 
    #492f2f 50%, 
    #452529 58.33%, 
    #411c25 66.67%, 
    #3d1423 75%, 
    #380c23 83.33%, 
    #330624 91.67%, 
    #2e0325 100%
    );
}

.forma02 {
  width: 13%;
  height: 25%;
  border-radius: 50%;
  position: absolute;
  right: -6%;
  bottom: -11.5%;
  /* background-image: radial-gradient(
    circle at 5.13% 12.35%,
    #653b5e 0,
    #572c59 12.5%,
    #461d55 25%,
    #300d50 37.5%,
    #0c004c 50%,
    #000048 62.5%,
    #000046 75%,
    #000043 87.5%,
    #000041 100%
  ); */
  /* background-image: radial-gradient(
    circle at 87.65% 94.87%,
    #98cd00 0,
    #7bcc00 16.67%,
    #57c900 33.33%,
    #13c600 50%,
    #00c21d 66.67%,
    #00be31 83.33%,
    #00bb42 100%
  ); */
  /*background-image: linear-gradient(
    110deg,
    #c6196b 0,
    #b0106a 16.67%,
    #970765 33.33%,
    #7c005d 50%,
    #610053 66.67%,
    #4a004a 83.33%,
    #350042 100%
  );*/
  background-image: radial-gradient(
    circle at 93.87% 80.72%, 
    #565a5e 0, 
    #545556 8.33%, 
    #52504f 16.67%, 
    #504946 25%, 
    #4e413e 33.33%, 
    #4c3936 41.67%, 
    #492f2f 50%, 
    #452529 58.33%, 
    #411c25 66.67%, 
    #3d1423 75%, 
    #380c23 83.33%, 
    #330624 91.67%, 
    #2e0325 100%
    );
}

.contenedor-superior {
  position: relative;
  display: flex;
  widows: 100%;
  height: 100%;
  background-color: #f0f0f0;
  overflow: hidden;
}

.card01 {
  position: absolute;
  border-radius: 10px;
  margin-top: 2.5%;
  background-color: white;
  width: 35%;
  height: 75%;

  z-index: 1;

  top: 0;
  left: 0;
  right: 33%;
  bottom: 0;
  margin: auto;

  box-shadow: 0px 0px 12px 10px rgba(143, 143, 143, 0.25);
}

.card02 {
  position: absolute;
  border-radius: 10px;
  background-color: #429a22; /* #0150f5; */
  width: 35%;
  height: 85%;

  top: 0;
  left: 33%;
  right: 0;
  bottom: 0;
  margin: auto;

  /* background: url("../../../public/img/7498967_33281.webp"); */

  box-shadow: 0px 0px 12px 10px rgba(143, 143, 143, 0.25);
}
.background-logo {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  z-index: 0;
}

.contenedor {
  display: flex;
  widows: 100%;
  height: 100%;
  font-family: sans-serif !important;
}

.bloque01 {
  width: 65%;
  height: 100%;
}

.bloque02 {
  display: flex;
  width: 35%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-textfield-custom {
  padding: 15px 20px 15px 40px;
  width: 100%;
  background: #ffffff !important;
  color: black !important;
  outline: none !important;
  margin: 15px 0 15px !important;
  box-shadow: 0px 0px 10px 7px rgba(143, 143, 143, 0.1);
  /* border-color: #26beba !important; */
  /* border: none !important; */
  /* border-style: solid !important;
  border-width: 1px !important; */

  /* border-left-color: #0150f5;
  border-width: 5px !important; */
  /* border-left: 3.5px solid #0150f5; */
  /* border-left: 3.5px solid #429a22; */
  border-left: 3.5px solid #9f1f63;
  /* border-style: solid !important; */
  border-radius: 5px;
  font-size: 25px !important;
  font-weight: 800;
}

.login-button-custom {
  width: 40%;

  height: 45px;
  border-radius: 5px;
  border: 1px solid #a3225e;/*#493030;*//*#9f1f63;*/
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 12px;
  padding-right: 12px;
  display: block;
  margin-top: 20px;
  margin-bottom: 4px;
  color: #ffffff;
  background-color: #a3225e;/*#493030;*//*#9f1f63;*/ /*26beba #0343bf  03164e*/
  cursor: pointer;
  font-family: sans-serif !important;
  font-size: 1rem;
  font-weight: bold;
  outline: none;
}

.login-title-text {
  font-size: 1.8rem !important;
  font-weight: 700 !important;
  /* text-transform: uppercase; */
  padding: 20px 0 5px;
  /* text-decoration: underline gray; */
  font-family: "Baloo Bhaina 2" !important;
}

.class-logo {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 70%;
}

.login-content {
  background-color: #03164e !important;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #ffffff;
  font-family: "Baloo Bhaina 2" !important;
  font-size: 16px !important;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #ffffff;
  font-family: "Baloo Bhaina 2" !important;
}

.login-logo {
  margin-bottom: 20px;
}

.login-title {
  display: block;
  margin-bottom: 15px;
}

.login-form {
  display: block !important;
  height: 250px !important;
}

.login-textfield {
  height: 40px !important;
  border-radius: 14px !important;
  border: 1px solid #244167 !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  display: block !important;
  margin-top: 20px !important;
  margin-bottom: 4px !important;
  width: 270px !important;
  color: #ffffff !important;
  background-color: #244167 !important;
  outline: none !important;
  font-family: "Baloo Bhaina 2" !important;
  font-size: 0.9rem !important;
}

.login-button {
  height: 45px;
  border-radius: 14px;
  border: 1px solid #26beba;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 12px;
  padding-right: 12px;
  display: block;
  margin-top: 20px;
  margin-bottom: 4px;
  width: 100%;
  color: #03164e;
  background-color: #26beba; /*26beba   03164e*/
  cursor: pointer;
  font-family: "Baloo Bhaina 2";
  font-size: 1rem;
  font-weight: bold;
  outline: none;
}

.login-title-text {
  font-size: 2.3rem !important;
  font-weight: 500 !important;
}

::-webkit-input-placeholder {
  /* Edge */
  color: #ffffff !important;
  font-family: "Baloo Bhaina 2" !important;
  font-size: 0.9rem !important;
}

:-ms-input-placeholder {
  /* Internet Explorer */
  color: #ffffff !important;
  font-family: "Baloo Bhaina 2" !important;
  font-size: 0.9rem !important;
}

::placeholder {
  color: #ffffff !important;
  font-family: "Baloo Bhaina 2" !important;
  font-size: 0.9rem !important;
}
</style>

<!--<style scoped>
html{
	height: 100%;
	background-color: #f4f6f8 !important;
}

body{
	margin: 0px !important;
	height: 100%;	
	color: #ffffff;	
    font-size: 16px !important;
}

.login-content{
	background-color: #03164e !important;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
    flex-direction: column;
    color: #ffffff;
    font-family: 'Baloo Bhaina 2' !important;
    font-size: 16px !important;
}

.login-container{
	display: flex;
	justify-content: center;
	align-items: center;
    flex-direction: column;
    color: #ffffff;
    font-family: 'Baloo Bhaina 2' !important;
}

.login-logo{
    margin-bottom: 20px;
}

.login-title{
    display: block;
    margin-bottom: 15px;
}

.login-form{
	display: block !important;
	height: 250px !important;
}

.login-textfield{
    height: 40px !important;
    border-radius: 14px !important;
    border: 1px solid #244167 !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    display: block !important;
    margin-top: 20px !important;
    margin-bottom: 4px !important;
    width: 270px !important;
    color: #ffffff !important;
    background-color: #244167 !important;
    outline: none !important;
    font-family: 'Baloo Bhaina 2' !important;
	font-size: .9rem !important;
}

.login-button{
	height: 45px;
    border-radius: 14px;
    border: 1px solid #26beba;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 12px;
    padding-right: 12px;
    display: block;
    margin-top: 20px;
    margin-bottom: 4px;
    width: 100%;
    color: #03164e;
    background-color: #26beba; /*26beba   03164e*/
    cursor: pointer;
    font-family: 'Baloo Bhaina 2';
    font-size: 1rem;
    font-weight: bold;
    outline: none;
}

.login-title-text{
	font-size: 2.3rem !important;
    font-weight: 500 !important;
}

::-webkit-input-placeholder { /* Edge */
	color: #ffffff !important;
	font-family: 'Baloo Bhaina 2' !important;
	font-size: .9rem !important;
}

:-ms-input-placeholder { /* Internet Explorer */
	color: #ffffff !important;
	font-family: 'Baloo Bhaina 2' !important;
	font-size: .9rem !important;
}

::placeholder {
	color: #ffffff !important;
	font-family: 'Baloo Bhaina 2' !important;
	font-size: .9rem !important;
}
</style>-->